"use strict";

// ------------------------------------------------------------------------
//
//
// Global Variables
//
//
// ------------------------------------------------------------------------
var app = {},
  parallaxSectionTop,
  parallaxLayers = [],
  hero = [],
  slides = [],
  me = $( "#me" ),
  meBg = $( "#js-main-nav-bg" ),
  cfBg = $( "#js-contact-form-bg" ),
  $smoothStateTargetClass = "",
  $svg_srcs = [],
  $svg_images = [],
  $counter,
  counter,
  $fixedElements;





// ------------------------------------------------------------------------
//
//
// Menu/Exit reset
//
//
// ------------------------------------------------------------------------
function meClose() {

  // console.log( '{ meClose }' );
  var $navLinks,
    i;

  // Clear active class
  me.removeClass( "is-active" ).data( "animating", "true" );

  // --------------------------------------------------------------------
  // Stagger out main nav
  // --------------------------------------------------------------------
  // Grab all main nav links
  $navLinks = $( ".nav-main .link" );

  for ( i = 0; i < $navLinks.length ; i++ ) {

    // In reverse order animate out
    $( $navLinks[ i ] ).velocity( { opacity: 0 }, { duration: 250, delay: i * 25 } );
  }

  // --------------------------------------------------------------------
  // Animate
  // --------------------------------------------------------------------
  $( "#me_m-m, #me_m-e, #me_m-n, #me_m-u" ).velocity( { fill: "#545454" }, { duration: 0 } );
  $( "#me_e-t, #me_e-i, #me_e-x, #me_e-e" ).velocity( { scale: [ 0, [ 0.6, 0, 0.5, 0.8 ] ], opacity: 0 }, 250, function () {

      meBg.velocity( { opacity: 0 }, 750, function () {

        // Hide menu background
        meBg.css( { visibility: "hidden" } );

      } );

    } );

  TweenLite.to( $( "#me_p" ), 0.5, { rotation: 0, delay: 0.2, svgOrigin: "250 250" } );
  $( "#me_p-1, #me_p-2" ).velocity( { fill: "#545454" }, { duration: 500, delay: 200 } );
  $( "#me_m-m" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 500 } );
  $( "#me_m-e" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 600 } );
  $( "#me_m-n" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 700 } );
  $( "#me_m-u" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 800, complete: function () {

      // At animation end
      me.data( "animating", "false" );

    } } );
}




// ------------------------------------------------------------------------
//
//
// Menu/Exit open
//
//
// ------------------------------------------------------------------------
function meOpen() {

  // console.log( '{ meOpen }' );

  var $navLinks,
    i;

  // Add active class
  me.addClass( "is-active" ).data( "animating", "true" );

  // Show menu background
  meBg.css( { visibility: "visible" } );

  // --------------------------------------------------------------------
  // Animate
  // --------------------------------------------------------------------
  $( "#me_e-e, #me_e-x, #me_e-i, #me_e-t" ).velocity( { fill: "#fff" }, { duration: 0 } );
  $( "#me_m-u, #me_m-n, #me_m-e, #me_m-m" ).velocity( { scale: [ 0, [ 0.6, 0, 0.5, 0.8 ] ], opacity: 0 }, 250, function () {

      meBg.velocity( { opacity: 1 }, 500, function () {

        // --------------------------------------------------------------------
        // Stagger in main nav
        // --------------------------------------------------------------------
        // Grab all main nav links
        $navLinks = $( ".nav-main .link" );

        for ( i = $navLinks.length - 1; i >= 0 ; i-- ) {

          // In reverse order animate in
          $( $navLinks[ i ] ).velocity( { opacity: [ 1, 0 ], translateY: [ 0, -200, [ 0.5, 0.2, 0.4, 1 ] ] }, { duration: 500, delay: ( $navLinks.length - i ) * 75 } );
        }

      } );

    } );
  // $( '#me_p' ).velocity({ rotateZ: 45 }, { duration: 500, delay: 200 });
  TweenLite.to( $( "#me_p" ), 0.5, { rotation: 45, delay: 0.2, svgOrigin: "250 250" } );
  $( "#me_p-1, #me_p-2" ).velocity( { fill: "#fff" }, { duration: 500, delay: 200 } );
  $( "#me_e-e" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 500 } );
  $( "#me_e-x" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 600 } );
  $( "#me_e-i" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 700 } );
  $( "#me_e-t" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 800, complete: function () {

      // At animation end
      me.data( "animating", "false" );
    } } );
}





// ------------------------------------------------------------------------
//
//
// Init Menu Exit menu reveal
//
//
// ------------------------------------------------------------------------
function initMenuExit() {

  // console.log( '{ initMenuExit }' );

  // Remove previous events attached
  me.off();

  // Menu/exit
  me.on( "click", function () {

    if ( me.data( "animating" ) !== "true" ) {

      // Test is already active (nav is open)
      if ( me.hasClass( "is-active" ) ) {

        meClose();

      } else {

        meOpen();
      }
    }

  } );

}



// ----------------------------------------------------------------------
//
// Contact Form Open
//
// ----------------------------------------------------------------------
function cfOpen() {

  // console.log( "{ cfOpen }" );

  // Add active class
  me.addClass( "is-active" ).data( "animating", "true" );

  // Show menu background
  cfBg.css( { visibility: "visible" } );

  // Set Main Menu position to fix iOS
  $fixedElements.css({ position: "absolute" });

  // --------------------------------------------------------------------
  // Animate
  // --------------------------------------------------------------------
  $( "#me_e-e, #me_e-x, #me_e-i, #me_e-t" ).velocity( { fill: "#424242" }, { duration: 0 } );
  $( "#me_m-u, #me_m-n, #me_m-e, #me_m-m" ).velocity( { scale: [ 0, [ 0.6, 0, 0.5, 0.8 ] ], opacity: 0 }, 250, function () {

    cfBg.velocity( { opacity: 1 }, 500, function () {

      // Set left to zero
      $( ".js-cf-wrapper" ).css( { display: "block", left: 0 } ).velocity( { opacity: 1 }, 250 );
      // console.log( "cfOpen callback" );
      positionContactForm();

    } );

  } );
  // $( '#me_p' ).velocity({ rotateZ: 45 }, { duration: 500, delay: 200 });
  TweenLite.to( $( "#me_p" ), 0.5, { rotation: 45, delay: 0.2, svgOrigin: "250 250" } );
  $( "#me_p-1, #me_p-2" ).velocity( { fill: "#424242" }, { duration: 500, delay: 200 } );
  $( "#me_e-e" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 500 } );
  $( "#me_e-x" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 600 } );
  $( "#me_e-i" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 700 } );
  $( "#me_e-t" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 800, complete: function () {

      // At animation end
      me.data( "animating", "false" );
    } } );

}



// ------------------------------------------------------------------------
//
//
// Contact Form overlay close
//
//
// ------------------------------------------------------------------------
function cfClose() {

  // console.log( '{ cfClose }' );

  // Clear active class
  me.removeClass( "is-active" ).data( "animating", "true" );

  // Set Main Menu position to fix iOS
  $fixedElements.css({ position: "fixed" });

  // Reset menu/exit event
  initMenuExit();

  // --------------------------------------------------------------------
  // Fade out form
  // --------------------------------------------------------------------
  $( ".js-cf-wrapper" ).velocity( { opacity: 0, display: "block" }, 250, function () {

    // Remove JS left style
    $( this ).css( "left", "" );

  } );

  // --------------------------------------------------------------------
  // Animate
  // --------------------------------------------------------------------
  $( "#me_m-m, #me_m-e, #me_m-n, #me_m-u" ).velocity( { fill: "#545454" }, { duration: 0 } );
  $( "#me_e-t, #me_e-i, #me_e-x, #me_e-e" ).velocity( { scale: [ 0, [ 0.6, 0, 0.5, 0.8 ] ], opacity: 0 }, 250, function () {

    cfBg.velocity( { opacity: 0 }, 750, function () {

      // Hide menu background
      cfBg.css( { visibility: "hidden" } );

    } );

  } );
  // $( '#me_p' ).velocity({ rotateZ: 0 }, { duration: 500, delay: 200 });
  TweenLite.to( $( "#me_p" ), 0.5, { rotation: 0, delay: 0.2, svgOrigin: "250 250" } );
  $( "#me_p-1, #me_p-2" ).velocity( { fill: "#545454" }, { duration: 500, delay: 200 } );
  $( "#me_m-m" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 500 } );
  $( "#me_m-e" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 600 } );
  $( "#me_m-n" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 700 } );
  $( "#me_m-u" ).velocity( { scale: [ 1, [ 0.5, 0.2, 0.4, 1 ] ], opacity: 1 }, { duration: 350, delay: 800, complete: function () {

      // At animation end
      me.data( "animating", "false" );

    } } );

}






// ------------------------------------------------------------------------
//
//
// Init Menu Exit contact form close
//
//
// ------------------------------------------------------------------------
function initMenuExitCF() {

  // console.log( '{ initMenuExitCF }' );

  // Remove previous events attached
  me.off();

  // Menu/exit
  me.on( "click", function () {

    // Close panel
    cfClose();
  } );
}




// ----------------------------------------------------------------------
//
// Init ContactForm
//
// ----------------------------------------------------------------------
function initContactForm() {

  // console.log( "{ initContactForm }" );

  var $contactForm = $( "#contact-form-118" )[ 0 ],
    result,
    xhr;

  // Check if form is on page
  if ( typeof $contactForm !== "undefined" ) {

    $( window ).on( "resize", ( $.debounce( 250, function ( e ) {

      // console.log( "window resize or scroll calling positionContactForm()" );
      positionContactForm();

    } ) ) );

    // Setup click event for contact enquiry
    $( "#contact-enquiry" ).on( "click", function () {

      // update Menu/Exit event
      initMenuExitCF();

      // Reveal form
      cfOpen();

    } );

    // Create stepFrom object with CF7 form
    new StepsForm( $contactForm, {
      onSubmit : function ( form ) {

        // Hide form
        $( ".wpcf7-form-inner" ).velocity( { opacity: 0 }, { display: "none" }, 250 );

        // Submit form data via ajax and store result
        result = $( form ).ajaxSubmit();
        xhr = result.data( "jqxhr" );

        // Listen for ajax done event
        xhr.done( function () {
          // console.log( '{ xhr done }' );

          // console.log( 'xhr: %o', xhr );
          // console.log( 'status: %o', xhr.status );

          if ( xhr.status === 200 ) {
            // Success!
            $( ".final-message" ).html( "<h2 class=\"top-title js-fit_l\">Thank you! We'll be in touch</h2>" ).addClass( "show" );
          } else {
            // Problemo
            $( ".final-message" ).html( "<h2 class=\"top-title js-fit_l\">There was a problem, please try again</h2>" ).addClass( "show" );
          }

          // Fade out and disable enquiry button
          $( "#contact-enquiry" ).velocity( { opacity: 0 }, { duration: 250, complete: function() {

            $( this ).css( { display: "none" } ).off();

          } } );

        } );
      }
    } ); // new StepsForm

  }
}




// ------------------------------------------------------------------------
//
//
// After ScrollReveal
//
//
// ------------------------------------------------------------------------
function srAfterReveal( $el, $class ) {

  // console.log( '{ srAfterReveal }' );

  $el.removeClass( "js-hide-i" ).addClass( $class );
}




// ------------------------------------------------------------------------
//
//
// ScrollReveal Setup
//
//
// ------------------------------------------------------------------------
function scrollRevealSetup() {

  // console.log( '{ scrollRevealSetup }' );

  var $viewedLastSection = false,
    srOptions = {
      distance : 0,
      duration : 1000,
      delay : 250,
      scale : 1,
      easing : "ease-out",
      mobile : false
    },
    srOptions2 = {
      origin : "bottom",
      distance : 0,
      reset : false,
      ratio : 0,
      duration : 0,
      delay : 0,
      scale : 1,
      mobile : true
    };

  if ( ! window.sr ) {

    // console.log( "! window.sr" );

    window.sr = ScrollReveal();
  }

  if ( $( ".js-sr_std" ).length ) {

    // Reveal standard
    sr.reveal( ".js-sr_std", srOptions );
  }

  if ( $( ".acf-a" ).length ) {

    // Sequentially reveal acf-a
    sr.reveal( ".acf-a .js-sr_seq", srOptions, 200 );
  }

  if ( $( ".acf-d" ).length ) {

    // Sequentially reveal acf-d
    sr.reveal( ".acf-d .js-sr_seq", srOptions, 200 );
  }

  if ( $( ".acf-e" ).length ) {

    // Sequentially reveal acf-e
    sr.reveal( ".acf-e .js-sr_seq", srOptions, 200 );
  }

  if ( $( ".acf-h" ).length ) {

    // Sequentially reveal acf-h
    sr.reveal( ".acf-h .js-sr_seq", srOptions, 200 );
  }

  if ( $( ".acf-i" ).length ) {

    // Sequentially reveal acf-i
    sr.reveal( ".acf-i .js-sr_seq", srOptions, 200 );
  }

  if ( $( ".acf-j" ).length ) {

    // Sequentially reveal acf-j
    sr.reveal( ".acf-j .js-sr_seq", srOptions, 200 );
  }

  if ( $( ".js-sr_right" ).length ) {

    // console.log( "{ $( '.js-sr_right' ).length }" );

    sr.reveal( ".js-sr_right", {
      distance : 30,
      duration : 0,
      delay : 0,
      scale : 1,
      easing : "ease-out",
      mobile : true,
      afterReveal : function ( domEl ) {

        if ( sr.tools.isMobile() ) {

          srAfterReveal( $( domEl ), "fadeIn" );

        } else {

          srAfterReveal( $( domEl ), "fadeInRight" );

        }
      }
    } );
  }

  if ( $( ".js-sr_left" ).length ) {

    // console.log( "{ $( '.js-sr_left' ).length }" );

    sr.reveal( ".js-sr_left", {
      distance : 30,
      duration : 0,
      delay : 0,
      scale : 1,
      easing : "ease-out",
      mobile : true,
      afterReveal : function ( domEl ) {

        if ( sr.tools.isMobile() ) {

          srAfterReveal( $( domEl ), "fadeIn" );

        } else {

          srAfterReveal( $( domEl ), "fadeInLeft" );

        }
      }
    } );
  }

  if ( $( "#home-two" ).length ) {

    enquire
    .register( "screen and (max-width:767px)", function () {

      sr.reveal( "#home-two", srOptions2 );

    } )
    .register( "screen and (min-width:768px)", function () {

      sr.reveal( "#home-two", {
        origin : "bottom",
        distance : 0,
        viewOffset : { top: -200, right: 0, bottom: 0, left: 0 },
        reset : true,
        ratio : 0,
        duration : 0,
        delay : 0,
        scale : 1,
        mobile : true,
        afterReveal : function ( domEl ) {

          if ( $viewedLastSection ) {

            $viewedLastSection = false;

            $.fn.fullpage.moveSectionUp();
          }

        }
      } );

    } );
  }

  if ( $( "#home-three" ).length ) {

    enquire
    .register( "screen and (max-width:767px)", function () {

      sr.reveal( "#home-three", srOptions2 );

    } )
    .register( "screen and (min-width:768px)", function () {

      sr.reveal( "#home-three", {
        origin : "bottom",
        distance : 0,
        viewOffset : { top: -200, right: 0, bottom: 0, left: 0 },
        reset : true,
        ratio : 0,
        duration : 0,
        delay : 0,
        scale : 1,
        mobile : true,
        afterReveal : function ( domEl ) {

          $viewedLastSection = true;

        }
      } );

    } );

  }
}



// ------------------------------------------------------------------------
//
//
// FullPage.js setup
//
//
// ------------------------------------------------------------------------
function fullPageScrollJackSetup() {

  // console.log( '{ fullPageScrollJackSetup }' );

  if ( $.fn.fullpage ) {

    var $fullpage = $( "#fullpage" ),
      $jsFullpage = $( ".js-fullpage" ),
      $timer;

    // FullPage not initialised - create (if #fullpage present)
    $fullpage.fullpage( {

      scrollingSpeed: 1250,
      // fitToSection: false,
      fitToSectionDelay: 0,
      scrollBar: false,
      normalScrollElements: ".normal-scroll",
      // touchSensitivity: 5,
      controlArrows: false,
      responsiveWidth: 768,
      sectionSelector: ".js-fullpage",
      slideSelector: ".slider",

      onLeave: function ( index, nextIndex, direction ) {
        // console.log( "onLeave" );
        // console.log( "index: %o", index );

        enquire
        .register( "screen and (min-width:768px)", function () {

          // console.log( "onLeave: $jsFullpage[ index - 1 ] %o", $jsFullpage[ index - 1 ] );

          $( $jsFullpage[ index - 1 ] ).velocity( { opacity: 0 }, 250 );

        } );
      },

      afterLoad: function ( anchorLink, index ) {
        // console.log( "afterLoad" );
        // console.log( "index: %o", index );

        enquire
        .register( "screen and (min-width:768px)", function () {

          // #home-one
          if ( index === 2 && ! $( "#home-one .js-sr_right" ).hasClass( "fadeInRight" ) ) {

            // --------------------------------------------------------------------
            // Stagger animation
            // --------------------------------------------------------------------
            // console.log( "{ Stagger animation }" );

            var $srSeq = $( "#home-one .js-sr_seq" );

            for ( i = 0; i < $srSeq.length ; i++ ) {

              // Animate in
              $( $srSeq[ i ] ).velocity( { opacity: 1 }, { duration: 1000, delay: ( i + 1 ) * 250 } );
            }

            $( "#home-one .js-sr_right" ).addClass( "fadeInRight" ).removeClass( "js-hide-i" );
            $( "#home-one .js-sr_left"  ).addClass( "fadeInLeft" ).removeClass( "js-hide-i" );
          }

          // #home-three
          if ( index === 4 ) {

            // console.log( "afterLoad: setFitToSection = false" );
            $.fn.fullpage.setFitToSection( false );
            $.fn.fullpage.setAutoScrolling( false );

          } else {

            // console.log( "afterLoad: setFitToSection = true" );
            $.fn.fullpage.setFitToSection( true );
            $.fn.fullpage.setAutoScrolling( true );

          }

          $( $jsFullpage[ index - 1 ] ).velocity( { opacity: 1 }, 500 );

        } );
      }

    } );

  }
}



// --------------------------------------------------------------------------------
//
// Scroll To Href...
//
// --------------------------------------------------------------------------------
function scrollToHref( anchor, anchorOffset ) {

  // console.log( '{ scrollToHref }' );
  // console.log( anchor );
  // console.log( anchorOffset );

  var offset = typeof anchor !== "undefined" ? $( $( anchor ) ).offset() : 0;

  // Set default value if none given
  anchorOffset = typeof anchorOffset !== "undefined" ? anchorOffset : 0;

  $( "html, body" ).velocity( "scroll", { offset: typeof offset.top !== "undefined" ? offset.top - anchorOffset : 0 + "px", duration: 1000 } );
}




// ------------------------------------------------------------------------
//
//
// Init FlowType (responsive typography)
//
//
// ------------------------------------------------------------------------
function initFlowType() {

  // console.log( '{ initFlowType }' );

  $( ".js-fit_m"   ).flowtype( { minFont: 25, maxFont: 48, fontRatio: 20, maximum: 1280 } );
  $( ".js-fit_l"   ).flowtype( { minFont: 40, maxFont: 72, fontRatio: 10, maximum: 1440 } );
  $( ".js-fit_xl"  ).flowtype( { minFont: 72, maxFont: 170, fontRatio: 10, maximum: 1440 } );
  $( ".js-fit_xxl" ).flowtype( { minFont: 72, maxFont: 320, fontRatio: 10 } );
  // $( '.js-fit_xxxl' ).flowtype({ minFont: 115, maxFont: 320, fontRatio: 5 });

}




// ------------------------------------------------------------------------
//
//
// Init buttons
//
//
// ------------------------------------------------------------------------
function initButtons() {

  // console.log( '{ initButtons }' );

  // --------------------------------------------------------------------
  // FullPage Next
  // --------------------------------------------------------------------
  $( ".js-fp-next" ).on( "click", function ( event ) {

    $.fn.fullpage.moveSectionDown();

    event.preventDefault();

  } );

  // --------------------------------------------------------------------
  // Isotope filter
  // --------------------------------------------------------------------
  $( ".js-filter-nav_toggle" ).on( "click", function ( event ) {

    // Open / close filter nav
    $( ".js-filter-nav" ).stop( true, true ).slideToggle();

    event.preventDefault();
  } );

  // --------------------------------------------------------------------
  // Scroll links
  // --------------------------------------------------------------------
  $( ".scroll" ).on( "click", function ( event ) {

    scrollToHref( $( this ).attr( "href" ) );

    event.preventDefault();
  } );

  // --------------------------------------------------------------------
  // SVG arrows
  // --------------------------------------------------------------------
  $( ".arrow" ).on( {
    mouseover: function () {

      $( this ).find( ".svg .a, .svg .b" ).velocity( { stroke: "#d3a782" }, 200 );
    },
    mouseleave: function () {

      $( this ).find( ".svg .a, .svg .b" ).velocity( { stroke: "#545454" }, 200 );
    }
  } );

  // --------------------------------------------------------------------
  // SVG buttons
  // --------------------------------------------------------------------
  $( ".link-button" ).on( {
    mouseover: function () {

      $( this ).velocity( { color: "#d3a782" }, 200 );
      $( this ).find( ".svg .a" ).velocity( { stroke: "#d3a782" }, 200 );
    },
    mouseleave: function () {

      $( this ).velocity( { color: "#545454" }, 200 );
      $( this ).find( ".svg .a" ).velocity( { stroke: "#545454" }, 200 );
    }
  } );

  // Menu Exit normal
  initMenuExit();

  // Listen for clicks to contact form bg
  $( "#js-contact-form-bg" ).on( "click", function ( event ) {

    if ( me.data( "animating" ) !== "true" ) {

      // Test is already active (nav is open)
      if ( me.hasClass( "is-active" ) ) {

        cfClose();
      }
    }
  } );

  // Listen for clicks to main nav bg
  $( "#js-main-nav-bg" ).on( "click", function ( event ) {

    if ( me.data( "animating" ) !== "true" ) {

      // Test is already active (nav is open)
      if ( me.hasClass( "is-active" ) ) {

        meClose();
      }
    }
  } );

  // Cancel out propagation
  $( ".wpcf7-form-control, button.next" ).on( "click", function ( event ) {
    // $( ".wpcf7-form-control, button.next, .nav-main .link" ).on( "click", function ( event ) {

    event.stopPropagation();
  } );
}




// ------------------------------------------------------------------------
//
//
// Carousel slides
//
//
// ------------------------------------------------------------------------
function carouselSlidesResize() {

  // console.log( '{ carouselSlidesResize }' );

  // Set js-slides height
  // line-height = 24px
  enquire
  .register( "screen and (max-width:767px)", function () {

    slides.css( "height", $( window ).height() - $( ".flex-caption" ).height() - ( 24 * 6 ) );
    $( ".js-slide_max" ).css( "height", $( window ).height() - $( ".flex-caption" ).height() - ( 24 * 4 ) );

  } )
  .register( "screen and (min-width:768px)", function () {

    slides.css( "height", $( window ).height() - $( ".flex-caption" ).height() - ( 24 * 9 ) );
    $( ".js-slide_max" ).css( "height", $( window ).height() - $( ".flex-caption" ).height() - ( 24 * 5 ) );

  } );

}




// ------------------------------------------------------------------------
//
//
// Setup Carousel slides
//
//
// ------------------------------------------------------------------------
function setupCarouselSlidesResize() {

  // console.log( '{ setupCarouselSlidesResize }' );

  slides = $( ".js-slide" );

  if ( slides.length !== 0 ) {

    carouselSlidesResize();

    $( window ).on( "resize", ( $.debounce( 250, function ( e ) {

      carouselSlidesResize();

    } ) ) );
  }

}



// ------------------------------------------------------------------------
//
//
// Hero
//
//
// ------------------------------------------------------------------------
function heroResize() {

  // console.log( '{ heroResize }' );

  // Set js-hero height to window height
  hero.css( "height", $( window ).height() );

  // Set inner elements to correct height
  $( ".js-hero_content-height" ).css( "height", $( ".js-hero_content" ).height() );
}



// ------------------------------------------------------------------------
//
//
// Setup Hero
//
//
// ------------------------------------------------------------------------
function setupHero() {

  // console.log( '{ setupHero }' );

  enquire
  .register( "screen and (min-width:768px)", function () {

    hero = $( ".js-hero" );

    if ( hero.length !== 0 ) {

      heroResize();

      $( window ).on( "resize", ( $.debounce( 250, function ( e ) {

        heroResize();

      } ) ) );
    }

  } );
}



// ------------------------------------------------------------------------
//
//
// Update 'sizes' attribute on srcset images for small screens
//
//
// ------------------------------------------------------------------------
function updateSizesAttr() {

  // console.log( "{ updateSizesAttr }" );

  enquire
  .register( "screen and (min-width:768px)", function () {

    // $( ".home-slides .responsive-image, .js-slide, .blog .featured-image_image, .single .featured-image_image, .page-template-default .featured-image_image" ).attr( "sizes", "67vw" );
    $( ".acf-a .col-5 .responsive-image" ).attr( "sizes", "41vw" );
    // $( ".acf-a .col-3 .bg-cover" ).attr( "sizes", "25vw" );
    $( ".acf-i .responsive-image" ).attr( "sizes", "21vw" );
    $( ".furnishings-hero .acf-parallax" ).attr( "data-sizes", "40vw" );
    $( ".acf-j .col-3 .responsive-image" ).attr( "sizes", "25vw" );
    $( ".acf-j .col-1 .responsive-image, .acf-j .col-2 .responsive-image" ).attr( "sizes", "20vw" );
    $( ".acf-k .bg-cover" ).attr( "data-sizes", "50vw" );
    $( ".single-post .responsive-image" ).attr( "data-sizes", "67vw" );
    $( ".single-post .col-6-blog .responsive-image" ).attr( "data-sizes", "32vw" );

  } );
}



// ------------------------------------------------------------------------
//
//
// Init ACF-A
//
//
// ------------------------------------------------------------------------
function initACF_A() {

  // console.log( '{ initACF_A }' );

  var $img2 = $( ".acf-a .img-wrap_2" ),
    $img3 = $( ".acf-a .img-wrap_3" );

  $img2.find( ".bg-cover" ).css( { height: $img2.height(), overflow: "hidden" } );
  $img3.find( ".bg-cover" ).css( { height: $img3.height(), overflow: "hidden" } );
}



// ------------------------------------------------------------------------
//
//
// Ajax Resets
//
//
// ------------------------------------------------------------------------
function ajaxResets() {

  // console.log( "{ ajaxResets }" );

  // Re-store main nav bg
  meBg = $( "#js-main-nav-bg" );

  // Re-store contact-form bg
  cfBg = $( "#js-contact-form-bg" );

  // Set var
  $fixedElements = $( "#me, .full-overlay" );

  // Count isotope parallax elements
  parallaxLayers = $( ".js-iso-parallax" );

  // Reset global vars
  parallaxLayers.length = hero.length = slides.length = $svg_images.length = $svg_srcs.length = $counter = 0;
  counter = "";

  // ----------------------------------------------------------------------------
  // Detach window watchers!
  // ----------------------------------------------------------------------------
  $( window ).off( "resize, scroll" );

  // Srcset sizes attr
  updateSizesAttr();

  // initLazysizes();

  if ( typeof picturefill === "function" ) {

    // Prompt picturefill to init
    picturefill();
  }

  // Setup Hero
  setupHero();

  // Setup carousel slides
  setupCarouselSlidesResize();

  // Scrollreveal
  scrollRevealSetup();

  // init Contact Form
  initContactForm();

  // Reset menu/exit icon
  meClose();

  // Init FlowType
  initFlowType();

  // Re-init Isotope
  initIsotope();

  // Init buttons
  initButtons();

  // FullPage.js setup
  if ( $( "html" ).hasClass( "fp-enabled" ) ) {

    // console.log( '{ destroying fullpage }' );

    // FullPage already initialised - destroy
    $.fn.fullpage.destroy( "all" );
  }
  fullPageScrollJackSetup();

}




// ----------------------------------------------------------------------
//
// Get Coords
//
// ----------------------------------------------------------------------
function getCoords( object ) {

  // console.log( "{ getCoords }" );
  // console.log( "object: %o", object );

  var coords = {};
  coords.left   = object.offset().left;
  coords.top    = object.offset().top - $( window ).scrollTop();
  coords.width  = object.width();
  coords.height = object.height();

  return coords;
}



// ////////////////////////////////////////////////////////////////////////////
//
//
// Position Contact Form
//
//
// ////////////////////////////////////////////////////////////////////////////
function positionContactForm() {

  // console.log( "{ positionContactForm }" );

  var $form = $( ".js-cf-wrapper" ),
    $window = $( window ),
    coords  = getCoords( $form ),
    screenWidth  = window.innerWidth  ? window.innerWidth  : document.documentElement.clientWidth  ? document.documentElement.clientWidth  : screen.width,
    screenHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height,
    left    = Number( Math.round( screenWidth * 0.1 + "e0" ) + "e-0" ),
    top     = ( screenHeight * 0.5 ) - ( coords.height * 0.5 );

  // console.log( "screenWidth: %o", screenWidth );
  // console.log( "screenHeight: %o", screenHeight );
  // console.log( "coords.height: %o", coords.height );
  // console.log( "left: %o", left );
  // console.log( "top: %o", top );

  // console.log( "parseInt( $form.css( 'left' ), 10 ): %o", parseInt( $form.css( "left" ), 10 ) < 0 );

  if ( parseInt( $form.css( "left" ), 10 ) < 0 ) {

    // console.log( "$form.css( 'left' ) < 0" );

    $form.css({
      top: top
    });

  } else {

    // console.log( "! $form.css( 'left' ) < 0" );

    $form.css({
      top: top,
      left: left
    });

  }

}



// ------------------------------------------------------------------------
//
//
// Isotope Parallax
//
//
// ------------------------------------------------------------------------
function isotopeParallax() {

  // console.log( '{ isotopeParallax }' );

  var jsIsoWrap = $( ".js-iso-wrap" );

  if ( jsIsoWrap.length ) {

    var depth = 1,
      i = 0,
      layer,
      movement = 0,
      scrollDistance = $( window ).scrollTop(),
      translate3d,
      parallaxSectionTop = jsIsoWrap.offset().top;

    for ( i; i < parallaxLayers.length; i++ ) {

      layer = parallaxLayers[ i ];
      depth = layer.getAttribute( "data-parallax" );
      movement = ( scrollDistance - parallaxSectionTop ) * -depth;

      translate3d = "translate3d(0, " + movement + "px, 0)";
      layer.style[ "-webkit-transform" ] = translate3d;
      layer.style[ "-moz-transform" ] = translate3d;
      layer.style[ "-ms-transform" ] = translate3d;
      layer.style[ "-o-transform" ] = translate3d;
      layer.style.transform = translate3d;
    }
  }
}





// ////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////////
//
//
// Module execution controller
//
//
// ////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////////
;( function ( $ ) {

  var $doc = $( document ),
    i;

  $.readyFn = {
    list: [],

    register: function ( fn ) {

      // console.log( "{ Module Registered }" );

      $.readyFn.list.push( fn );

    },

    execute: function () {

      // console.log( "{ Module Execute }" );

      for ( i = 0; i < $.readyFn.list.length; i++ ) {

        try {

          $.readyFn.list[ i ].apply( document, [ $ ] );

        } catch ( e ) {

          throw e;

        }
      }
    }
  };

  app = {

    // ----------------------------------------------------------------------------
    //
    // Init SmoothState
    //
    // ----------------------------------------------------------------------------
    initSmoothState: function () {

      // console.log( "{ initSmoothState }" );

      var $pageTemp = null,
        $pageFrame = null,
        coords,
        $page = $( "#js-ajax-container" ),
        $smoothStateOptions = {
          debug: true,
          prefetch: true,
          forms: false,
          pageCacheSize: 2,
          cacheLength: 2,

          onBefore: function ( $currentTarget, $container ) {

            // console.log( "{ smoothState: onBefore }" );
            // console.log( '$currentTarget: %o', $currentTarget );
            // console.log( '$container: %o', $container );

            // Get classes of $currentTarget
            $smoothStateTargetClass = typeof $currentTarget.attr( "class" ) !== "undefined" ? $currentTarget.attr( "class" ) : "";

            if ( $smoothStateTargetClass.indexOf( "js-ss" ) > -1 && $smoothStateTargetClass !== "" ) {

              if ( $smoothStateTargetClass.indexOf( "js-ss_3" ) > -1 ) {

                $currentTarget = $currentTarget.parents( "article" ).find( ".js-ss_one" );
                $smoothStateTargetClass = "js-ss_one";

              } else if ( $smoothStateTargetClass.indexOf( "js-ss_4" ) > -1 ) {

                $currentTarget = $currentTarget.parents( ".slide" ).find( ".js-ss_two" );
                $smoothStateTargetClass = "js-ss_two";
              }

              // Get coords of element clicked-on
              coords = getCoords( $currentTarget );
              // console.log( "coords: %o", coords );

              // Create gold frame HTML element
              $( "body" ).prepend( "<div id=\"js-ajax-frame\" class=\"gold-frame-container\"><div class=\"js-frame gold-frame is-entering\"></div></div>" );
              $pageFrame = $( "#js-ajax-frame" );

              // Set size & position of frame
              $pageFrame.find( ".js-frame" ).css( {
                "width" : coords.width,
                "height" : coords.height,
                "margin-top" : coords.top,
                "margin-left": coords.left
              } );

            }
          },

          onStart: {
            duration: 500, // Duration of our animation
            render: function ( $container ) {

              // console.log( "{ smoothState: onStart }" );
              // console.log( '$container: %o', $container );

              // Fade out content container
              $container.velocity( { opacity: 0 }, 500 );
            }
          },

          onReady: {
            duration: 0,
            render: function( $container, $newContent ) {

              // console.log( "{ smoothState: onReady }" );
              // console.log( '$container: %o', $container );
              // console.log( '$newContent: %o', $newContent[0].innerHTML );

            }
          },

          onAfter: function ( $container, $newContent ) {

            // console.log( "{ smoothState: onAfter }" );

            // Filter actions by class
            if ( $smoothStateTargetClass.indexOf( "js-ss" ) > -1 && $smoothStateTargetClass !== "" ) {

              // Insert new page content
              $container.html( $newContent );

              // Resets to adjust image sizes
              ajaxResets();

              // Prompt lazysizes to check all new elements
              // lazySizes.loader.checkElems();

              if ( $smoothStateTargetClass.indexOf( "js-ss_one" ) > -1 || $smoothStateTargetClass.indexOf( "js-ss_3" ) > -1 ) {
                // console.log( 'clicked on link with js-ss_one || js-ss_3' );

                // Get coords of featured image
                coords = getCoords( $container.find( ".featured-image" ) );

              } else if ( $smoothStateTargetClass.indexOf( "js-ss_two" ) > -1 || $smoothStateTargetClass.indexOf( "js-ss_4" ) > -1 ) {
                // console.log( 'clicked on link with js-ss_two || js-ss_4' );

                // Get coords of hero image
                coords = getCoords( $container.find( ".hero-image:visible" ) );
              }
              // console.log( "coords: %o", coords );

              // Animate gold frame to new size & position
              $pageFrame.find( ".js-frame" ).velocity( {
                width: coords.width,
                height: coords.height,
                marginTop: coords.top,
                marginLeft: coords.left
              }, 500, function () {

                // Clear
                $smoothStateTargetClass = "";

                // Fade in new content container
                $container.velocity( { opacity: 1 }, 500 );

                // Fade out gold frame
                $pageFrame.velocity( { opacity: 0 }, 500, function () {

                  // Remove gold frame
                  $( "#js-ajax-frame" ).remove();

                } );

              } );

            } else {

              // console.log( '{ Not a link with .js-ss_... class. Could also be Back / Forward }' );

              // Insert new page content
              $container.html( $newContent );

              // Resets
              ajaxResets();

              // Clear
              $smoothStateTargetClass = "";

              // Fade in new content container
              $container.velocity( { opacity: 1 }, 500 );
            }

            $.readyFn.execute();

          }
        };

      this.smoothState = $page.smoothState( $smoothStateOptions ).data( "smoothState" );

    },

    // ----------------------------------------------------------------------------
    //
    // Init Google Maps
    //
    // ----------------------------------------------------------------------------
    initGoogleMaps: function () {

      // console.log( "{ initGoogleMaps }" );

      this.$mapContainer = document.getElementById( "contact-map" );

      if ( this.$mapContainer ) {

        // console.log( "#contact-map found" );

        if ( window.google && window.google.maps ) {

          // console.log( "window.google && window.google.maps" );

          this.makeGoogleMaps();

        } else {

          this.loadGoogleMaps();

        }
      }
    },

    // ----------------------------------------------------------------------------
    //
    // Init Contact Form 7 Scripts
    //
    // ----------------------------------------------------------------------------
    initContactForm: function () {

      // console.log( "{ initContactForm }" );

      var $contactForm = $( "#contact-form-118" )[ 0 ],
        $bodyClasses = $( "#js-body" ),
        jsForm,
        fjsForm;

      // Check if form is on page
      if ( $bodyClasses.hasClass( "page-template-template-contact" ) ) {

        if ( $.fn.ajaxSubmit ) {

          return;
        }

        fjsForm    = document.getElementsByTagName( "script" )[ 0 ];
        jsForm     = document.createElement( "script" );
        jsForm.src = sageJsVars.pluginsPath + "/contact-form-7/includes/js/jquery.form.min.js";
        fjsForm.parentNode.appendChild( jsForm, fjsForm );

      }

    },

    // ----------------------------------------------------------------------------
    //
    // Load Google Maps
    //
    // ----------------------------------------------------------------------------
    loadGoogleMaps: function () {

      // console.log( "{ loadGoogleMaps }" );

      var js,
        lg,
        fjs,
        id = "google-maps-api";

      if ( document.getElementById( id ) ) {

        return;
      }

      lg     = document.documentElement.getAttribute( "lang" );
      fjs    = document.getElementsByTagName( "script" )[ 0 ];
      js     = document.createElement( "script" );
      js.id  = id;
      js.src = "//maps.googleapis.com/maps/api/js?callback=app.makeGoogleMaps&key=AIzaSyCVNhPbqud_cgGWW4jgcODpdC0uL0oZE1Q";
      js.setAttribute( "async", "" );
      js.setAttribute( "defer", "" );
      fjs.parentNode.insertBefore( js, fjs );

    },

    // ----------------------------------------------------------------------------
    //
    // Make Google Maps
    //
    // ----------------------------------------------------------------------------
    makeGoogleMaps: function () {

      // console.log( "{ makeGoogleMaps }" );

      // Use snazzymaps https://snazzymaps.com for 'styles'
      // https://developers.google.com/maps/documentation/javascript/get-api-key
      var $markerIcon = sageJsVars.templatePath + "/dist/images/map-marker.png",
        $mapStyles = [
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [ { "color": "#e9e9e9" }, { "lightness": 17 } ]
          },
          {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [ { "color": "#f5f5f5" }, { "lightness": 20 } ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [ { "color": "#ffffff" }, { "lightness": 17 } ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [ { "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 } ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [ { "color": "#ffffff" }, { "lightness": 18 } ]
          },
          {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [ { "color": "#ffffff" }, { "lightness": 16 } ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [ { "color": "#f5f5f5" }, { "lightness": 21 } ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [ { "color": "#dedede" }, { "lightness": 21 } ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [ { "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 } ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [ { "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 } ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [ { "visibility": "off" } ]
          },
          {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [ { "color": "#f2f2f2" }, { "lightness": 19 } ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [ { "color": "#fefefe" }, { "lightness": 20 } ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [ { "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 } ]
          }
        ],
        $mapOptions,
        $map,
        $marker,
        $styledMapType,
        $latLng;

      // --------------------------------------------------------------------
      // Enquire - slightly different centers for small / large screens
      // --------------------------------------------------------------------
      $markerLatLng = new google.maps.LatLng( 52.6715361, -0.7308584 );
      enquire
      .register( "screen and (max-width:767px)", function () {

        $latLng = $markerLatLng;
        $mapOptions = {
          mapTypeControlOptions: { mapTypeIds: [ "" ] },
          center: $latLng,
          zoom: 14,
          disableDefaultUI: true,
          mapTypeId: "Styled",
          scrollwheel: false,
          draggable: false
        };

      } )
      .register( "screen and (min-width:768px)", function () {

        $latLng = new google.maps.LatLng( 52.6715361, -0.7278584 );
        $mapOptions = {
          mapTypeControlOptions: { mapTypeIds: [ "" ] },
          center: $latLng,
          zoom: 14,
          disableDefaultUI: true,
          mapTypeId: "Styled",
          scrollwheel: false
        };

      } );

      $map = new google.maps.Map( this.$mapContainer, $mapOptions );
      $styledMapType = new google.maps.StyledMapType( $mapStyles, { name: "Styled" } );

      $map.mapTypes.set( "Styled", $styledMapType );

      // --------------------------------------------------------------------------------
      // Creating a marker and positioning it on the map
      // --------------------------------------------------------------------------------
      $marker = new google.maps.Marker( {
        position: $latLng,
        map: $map,
        icon: $markerIcon
      } );

    }
  };

  $doc.ready( function () {

    // console.log( "{ $doc.ready }" );

    ajaxResets();

    app.initSmoothState();

    initLazysizes();

    $.readyFn.execute();

  } );

  $.fn.ready = $.readyFn.register;

} )( jQuery );

$( function() {

  // ----------------------------------------------------------------------------
  // Setup Google Maps if refresh/land on Contact Page
  // ----------------------------------------------------------------------------
  app.initGoogleMaps();

  // ----------------------------------------------------------------------------
  // Setup Contact Form 7 if refresh/land on Contact Page
  // ----------------------------------------------------------------------------
  app.initContactForm();

} );
