// ////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////////
// ----------------------------------------------------------------------------
//
//
//     dMP     .aMMMb  dMMMMMP dMP dMP .dMMMb  dMP dMMMMMP dMMMMMP .dMMMb
//    dMP     dMP"dMP   .dMP" dMP.dMP dMP" VP amr   .dMP" dMP     dMP" VP
//   dMP     dMMMMMP  .dMP"   VMMMMP  VMMMb  dMP  .dMP"  dMMMP    VMMMb
//  dMP     dMP dMP .dMP"   dA .dMP dP .dMP dMP .dMP"   dMP     dP .dMP
// dMMMMMP dMP dMP dMMMMMP  VMMMP"  VMMMP" dMP dMMMMMP dMMMMMP  VMMMP"
//
//
// ----------------------------------------------------------------------------
// ////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////////
// jQuery( document ).ready( function( $ ) {
function initLazysizes() {

  // --------------------------------------------------------------------------------
  //
  // Lazysizes settings
  //
  // --------------------------------------------------------------------------------
  window.lazySizesConfig = window.lazySizesConfig || {};

  // set distance from visible area that element lazyloads
  lazySizesConfig.expand = 0;

  document.addEventListener( "lazybeforeunveil", function ( e ) {

    // console.log( 'e.target: %o', e.target );
    // console.log( "e.target.className: %o", e.target.className );
    // console.log( "e.target.className.baseVal: %o", e.target.className.baseVal );

    // ----------------------------------------------------------------------
    //
    // SVG Arrows
    //
    // ----------------------------------------------------------------------
    if ( e.target.className.baseVal !== undefined ) {

      if ( e.target.className.baseVal.search( "arrow" ) !== -1 || e.target.className.baseVal.search( "link-border" ) !== -1 ) {

        // console.log( "{ Lazysizes: svg }" );
        // console.log( "e.target.id: %o", e.target.id );

        new Vivus( e.target.id, { type: "delayed", duration: 150, start: "autostart" } );
      }
    }

    // ----------------------------------------------------------------------
    //
    // Background images
    //
    // ----------------------------------------------------------------------
    else if ( e.target.getAttribute( "data-bg" ) ) {

      // console.log( '{ Lazysizes: data-bg }' );

      e.target.style.backgroundImage = "url(" + bg + ")";
    }

    // ----------------------------------------------------------------------
    //
    // Isotope
    //
    // ----------------------------------------------------------------------
    else if ( e.target.className.search( "sig-image" ) !== -1 ) {

      // console.log( '{ Lazysizes: sig-image }' );

      // ----------------------------------------------------------------------
      //
      // Isotope Parallax - Signature Pieces
      //
      // ----------------------------------------------------------------------
      enquire
      .register( "screen and (min-width:768px)", function () {

        isotopeParallax();

        $( window ).on( "scroll", ( $.debounce( 5, function ( e ) {

          isotopeParallax();

        } ) ) );

      } );

      if ( typeof $.Isotope !== "undefined" ) {

        // console.log( '{ Lazysizes: Isotope }' );

        updateIsotope();
      }
    }

    // --------------------------------------------------------------------------------
    //
    // ACF-A - Module
    //
    // --------------------------------------------------------------------------------
    else if ( e.target.className.search( "js-acf-a" ) !== -1 ) {

      // console.log( '{ Lazysizes: acf-a }' );

      initACF_A();

      $( window ).on( "resize", ( $.debounce( 250, function ( e ) {

        initACF_A();

      } ) ) );

    }

    // --------------------------------------------------------------------------------
    //
    // ACF-C - Parallax module
    //
    // --------------------------------------------------------------------------------
    else if ( e.target.className.search( "js-parallax" ) !== -1 ) {

      // console.log( '{ Lazysizes: js-parallax }' );

      $( ".js-parallax" ).parallax();
    }

    // --------------------------------------------------------------------------------
    //
    // ACF-K - bgset
    //
    // --------------------------------------------------------------------------------
    // else if ( e.target.className.search( 'acf-parallax' ) !== -1 ) {

      // console.log( '{ Lazysizes: acf-parallax }' );

      // $( e.target ).velocity( {opacity: [ 1, 0 ] }, 1000 );
    // }

    // --------------------------------------------------------------------------------
    //
    // acf-carousel - flexslider setup
    //
    // --------------------------------------------------------------------------------
    else if ( e.target.className.search( "js-slide" ) !== -1 ) {

      // console.log( '{ Lazysizes: js-slide }' );

      jQuery( ".js-carousel" ).flexslider( {
        animation: "fade",
        selector: ".slide",
        easing: "easeOutQuad",
        pauseOnHover: true,
        controlNav: false,
        slideshowSpeed: 4000,
        animationSpeed: 500,
        customDirectionNav: $( ".link-flex" )
      } );
    }

  } );

}
