// ----------------------------------------------------------------------
//
// Custom Isotope layout mode spineAlign ( requires Isotope v1 )
//
// ----------------------------------------------------------------------
if ( typeof $.Isotope !== "undefined" ) {

  $.Isotope.prototype._spineAlignReset = function () {
    this.spineAlign = {
      colA: 0,
      colB: 0
    };
  };

  $.Isotope.prototype._spineAlignLayout = function ( $elems ) {
    var instance = this,
      props = this.spineAlign,
      gutterWidth = Math.round( this.options.spineAlign && this.options.spineAlign.gutterWidth ) || 0,
      centerX = Math.round( this.element.width() / 2 );

    $elems.each( function () {
      var $this = $( this ),
        isColA = props.colA <= props.colB,
        x = isColA ? centerX - ( $this.outerWidth( true ) + gutterWidth / 2 ) : // left side
        centerX + gutterWidth / 2, // right side
        y = isColA ? props.colA : props.colB;

      instance._pushPosition( $this, x, y );
      props[ ( isColA ? "colA" : "colB" ) ] += $this.outerHeight( true );
    } );
  };

  $.Isotope.prototype._spineAlignGetContainerSize = function () {
    var size = {};
    size.height = this.spineAlign[ ( this.spineAlign.colB > this.spineAlign.colA ? "colB" : "colA" ) ];
    return size;
  };

  $.Isotope.prototype._spineAlignResizeChanged = function () {
    return true;
  };
}

// ------------------------------------------------------------------------
//
//
// Update Isotope layout
//
//
// ------------------------------------------------------------------------
function updateIsotope() {

  // console.log( "{ updateIsotope }" );

  var $isotopeContainer = $( ".js-iso-wrap" );

  // --------------------------------------------------------------------
  // Isotope Init
  // --------------------------------------------------------------------
  enquire
  .register( "screen and (min-width:768px)", function () {

    $isotopeContainer.isotope( {

      itemSelector: ".sig-item",

      layoutMode: "spineAlign",
      spineAlign: {
        gutterWidth: 40
      },

      hiddenStyle: {
        opacity: 0
      },
      visibleStyle: {
        opacity: 1
      }

    } );

  } );
}

// ------------------------------------------------------------------------
//
//
// Initialise Isotope setup
//
//
// ------------------------------------------------------------------------
function initIsotope() {

  // console.log( '{ initIsotope }' );

  // jQuery( document ).ready( function ( $ ) {

    // console.log( '{ initIsotope ready }' );

    var $isotopeContainer = $( ".js-iso-wrap" ),
      $filterLinks = $( ".js-nav-filter" );

    // --------------------------------------------------------------------
    // Filter links
    // --------------------------------------------------------------------
    $filterLinks.on( "click", function ( e ) {

      // console.log( '{ click filter }' );
      // --------------------------------------------------------------------
      // If large screens
      // --------------------------------------------------------------------
      if ( $( ".js-filter-nav_toggle" ).is( ":hidden" ) ) {

        $isotopeContainer.isotope( {

          filter: $( this ).attr( "data-filter" )

        } );

      }
      // --------------------------------------------------------------------
      // If small screens
      // --------------------------------------------------------------------
      else {

        // Close nav list
        $( ".js-filter-nav" ).slideToggle();

        // Update filter text
        $( ".js-filter-text" ).text( $( this ).text() );

        // console.log( 'a: %o', $(this).data( 'filter' ) );
        // console.log( 'b: %o', $( '.sig-item' ).not( $(this).data( 'filter' ) ) );
        // console.log( 'c: %o', $( '.sig-item' + $(this).data( 'filter' ) ) );

        $filter = $( this ).data( "filter" );

        if ( $filter === "*" ) {

          $( ".sig-item" ).css( { display: "block" } ).velocity( { translateY: 0 }, 0 ).velocity( { opacity: 1 }, 400 );

        } else {

          $( ".sig-item" ).not( $filter ).velocity( { opacity: 0, translateY: 10000 }, 250, function () {

            $( this ).css( { display: "none" } ).velocity( { translateY: 0 }, 0 );
            $( ".sig-item" + $filter ).css( { display: "block" } ).velocity( { opacity: 1 }, 400 );

          } );
        }
      }

      $filterLinks.removeClass( "is-active" );

      $( this ).addClass( "is-active" );

      e.preventDefault();
    } );

    updateIsotope();

  // } );
}
