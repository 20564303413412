// //////////////////////////////////////////////////////
// //////////////////////////////////////////////////////
//
// Parallax background-image
//
// v1.2
//
// Author: James Hobson
//
// v1.2 - 15th June 2016 - Added check for debounce
// v1.1 - 4th August 2015 - Changed rounding calculation - Added debounce setup
// v1.0 - 28th July 2015 - Initial release
//
// //////////////////////////////////////////////////////
// //////////////////////////////////////////////////////
( function ( $ ) {

  $.fn.parallax = function () {

    var windowHeight,
        elem = this;

    elem.each( function () {

      // Store constants
      $( this ).data( "elemHeight", $( this ).height() );
      $( this ).data( "elemScrollTop", $( this ).offset().top );
      $( this ).data( "elemOffTop", $( this ).data( "elemScrollTop" ) + $( this ).data( "elemHeight" ) );
    } );

    function scrollWatch() {

      elem.each( function () {

        var windowScrollTop = $( window ).scrollTop(),
            elemOffBottom = windowScrollTop + windowHeight,
            sum;

        if ( $( this ).data( "elemScrollTop" ) <= elemOffBottom && $( this ).data( "elemOffTop" ) >= windowScrollTop ) {

          sum = ( ( windowScrollTop - $( this ).data( "elemOffTop" ) ) / ( windowHeight + $( this ).data( "elemHeight" ) ) );
          sum = -Number( Math.round( sum + "e3" ) + "e-1" );
          $( this ).css( "background-position", "50% " + sum + "%" );
        }
      } );
    }

    function resizeWatch() {

      windowHeight = $( window ).height();
      scrollWatch();
    }

    if ( window.jQuery.throttle ) {

      // Using debounce
      // https://github.com/cowboy/jquery-throttle-debounce
      $( window ).resize( $.debounce( 250, function ( e ) {
        resizeWatch();
      } ) );
      $( window ).scroll( $.debounce( 5, function ( e ) {
        scrollWatch();
      } ) );

    } else {

      $( window ).resize( resizeWatch );
      $( window ).scroll( scrollWatch );
    }

    // Init
    resizeWatch();
  };

} )( jQuery );
